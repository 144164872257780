import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import Chart from "../components/Chart";
import { Select, Space } from "antd";
import bg from "/images/bg.png";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DrilldownModule from "highcharts/modules/drilldown";
import api from "../util/api";
import "./index.css";

if (typeof Highcharts === "object") {
  DrilldownModule(Highcharts);
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const styleCard = {
  borderRadius: 12,
  padding: "12px",
  margin: "18px 12px 18px 12px",
  backgroundColor: "#fff",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  display: "flex",
  flexDirection: "column",
};

const MAP_YEAR = {
  y25612: "2/2561",
  y25621: "1/2562",
  y25622: "2/2562",
  y25631: "1/2563",
  y25632: "2/2563",
  y25641: "1/2564",
  y25642: "2/2564",
  y25651: "1/2565",
};

const mapData = (data, filter = [], record_type) => {
  let SUFFIX_YEAR_INDEX = {
    y25612: {
      key: "y25612",
      year: 2018,
      semeter: 2,
      ...data.y25612[record_type],
    },
    y25621: {
      key: "y25621",
      year: 2019,
      semeter: 1,
      ...data.y25621[record_type],
    },
    y25622: {
      key: "y25622",
      year: 2019,
      semeter: 2,
      ...data.y25622[record_type],
    },
    y25631: {
      key: "y25631",
      year: 2020,
      semeter: 1,
      ...data.y25631[record_type],
    },
    y25632: {
      key: "y25632",
      year: 2020,
      semeter: 2,
      ...data.y25632[record_type],
    },
    y25641: {
      key: "y25641",
      year: 2021,
      semeter: 1,
      ...data.y25641[record_type],
    },
    y25642: {
      key: "y25642",
      year: 2021,
      semeter: 2,
      ...data.y25642[record_type],
    },
    y25651: {
      key: "y25651",
      year: 2022,
      semeter: 1,
      ...data.y25651[record_type],
    },
  };

  let chartIncomeTable = [];
  filter.forEach((key_year) => {
    let _data = SUFFIX_YEAR_INDEX[key_year];
    chartIncomeTable.push({
      title: `${_data.semeter} / ${_data.year + 543}`,
      avg: `${(SUFFIX_YEAR_INDEX[key_year].sum_money / SUFFIX_YEAR_INDEX[key_year].total_form).toFixed(2)}`,
      menber: `${(SUFFIX_YEAR_INDEX[key_year].avg_member / SUFFIX_YEAR_INDEX[key_year].total_form).toFixed(0)}`,
    });
  });

  let ChartInCome = [];
  let ChartDifficultConditions = [
    {
      name: "มีภาระพึ่งพิง",
      color: "#2dd4bf",
      data: [],
    },
    {
      name: "มีสภาพบ้านชำรุด",
      color: "#38bdf8",
      data: [],
    },
    {
      name: "ไม่มียานพาหนะ",
      color: "#818cf8",
      data: [],
    },
    {
      name: "ไม่มีที่ดินทำกิน",
      color: "#FFADF0",
      data: [],
    },
  ];

  let ChartLight = [
    {
      name: "ไม่มีไฟฟ้าหรือเครื่องกำเนิดไฟฟ้า",
      color: "#2dd4bf",
      data: [],
    },
    {
      name: "ใช้เครื่องปั่นไฟ",
      color: "#38bdf8",
      data: [],
    },
    {
      name: "ใช้ไฟต่อพ่วง",
      color: "#818cf8",
      data: [],
    },
    {
      name: "ใช้ไฟมิเตอร์และอื่นๆ",
      color: "#FFADF0",
      data: [],
    },
  ];

  let ChartAppliances = [
    {
      name: "เครื่องปรับอากาศ",
      color: "#2dd4bf",
      data: [],
    },
    {
      name: "โทรทัศน์",
      color: "#38bdf8",
      data: [],
    },
    {
      name: "คอมพิวเตอร์",
      color: "#818cf8",
      data: [],
    },
    {
      name: "ตู้เย็น",
      color: "#FFADF0",
      data: [],
    },
    {
      name: "เครื่องซักผ้า",
      color: "#FA8072",
      data: [],
    },
  ];

  let ChartDependency = [
    {
      name: "มีคนพิการ/เจ็บป่วยเรื้อรัง",
      color: "#2dd4bf",
      data: [],
    },
    {
      name: "ผู้สูงอายุเกินกว่า 60 ปี",
      color: "#38bdf8",
      data: [],
    },
    {
      name: "มีคนอายุ 15-65 ปีที่ว่างงาน",
      color: "#818cf8",
      data: [],
    },
    {
      name: "เป็นพ่อ/แม่เลี้ยงเดี่ยว",
      color: "#FFADF0",
      data: [],
    },
  ];

  let ChartHouse = [
    {
      name: "มีสภาพทรุดโทรม/ไม่ปลอดภัย",
      color: "#2dd4bf",
      data: [],
    },
    {
      name: "ไม่มีห้องส้วมในที่อยู่อาศัยและบริเวณบ้าน",
      color: "#38bdf8",
      data: [],
    },
  ];
  let ChartWater = [
    {
      name: "น้ำประปา",
      color: "#2dd4bf",
      data: [],
    },
    {
      name: "น้ำบ่อ/น้ำฝน/น้ำประปาภูเขา/แม่น้ำลำธาร",
      color: "#38bdf8",
      data: [],
    },
    {
      name: "น้ำบาดาล",
      color: "#818cf8",
      data: [],
    },
    {
      name: "แหล่งน้ำ อื่นๆ",
      color: "#FFADF0",
      data: [],
    },
  ];

  let ChartLand = [
    {
      name: "ไม่มีที่ดินทำกิน",
      color: "#2dd4bf",
      data: [],
    },
    {
      name: "มีที่ดินทำกินไม่เกิน 1 ไร่",
      color: "#38bdf8",
      data: [],
    },
    {
      name: "มีที่ดินทำกินได้ 1 ถึง 5 ไร่",
      color: "#818cf8",
      data: [],
    },
  ];

  filter.forEach((x) => {
    let _data = SUFFIX_YEAR_INDEX[x];
    ChartInCome.push({
      name: MAP_YEAR[x],
      y: _data.sum_money / _data.total_form,
      color: "#0261C8",
      drilldown: MAP_YEAR[x],
    });

    let sum_difficultConditions = _data.has_burden + _data.IsRuinedHouse + _data.has_no_car + _data.NoOwnLand;

    ChartDifficultConditions[0].data.push({
      y: _data.has_burden,
      total: (_data.has_burden / sum_difficultConditions) * 100,
    });
    ChartDifficultConditions[1].data.push({
      y: _data.IsRuinedHouse,
      total: (_data.IsRuinedHouse / sum_difficultConditions) * 100,
    });
    ChartDifficultConditions[2].data.push({
      y: _data.has_no_car,
      total: (_data.has_no_car / sum_difficultConditions) * 100,
    });
    ChartDifficultConditions[3].data.push({
      y: _data.NoOwnLand,
      total: (_data.NoOwnLand / sum_difficultConditions) * 100,
    });

    let sum_light = _data.NoLight + _data.GeneratorLight + _data.ExtensionLights + _data.OtherLight;

    ChartLight[0].data.push({
      y: _data.NoLight,
      total: (_data.NoLight / sum_light) * 100,
    });
    ChartLight[1].data.push({
      y: _data.GeneratorLight,
      total: (_data.GeneratorLight / sum_light) * 100,
    });
    ChartLight[2].data.push({
      y: _data.ExtensionLights,
      total: (_data.ExtensionLights / sum_light) * 100,
    });
    ChartLight[3].data.push({
      y: _data.OtherLight,
      total: (_data.OtherLight / sum_light) * 100,
    });

    let sum_appliances = _data.Air + _data.Tv + _data.Pc + _data.Freeze + _data.Wash;

    ChartAppliances[0].data.push({
      y: _data.Air,
      total: (_data.Air / sum_appliances) * 100,
    });
    ChartAppliances[1].data.push({
      y: _data.Tv,
      total: (_data.Tv / sum_appliances) * 100,
    });
    ChartAppliances[2].data.push({
      y: _data.Pc,
      total: (_data.Pc / sum_appliances) * 100,
    });
    ChartAppliances[3].data.push({
      y: _data.Freeze,
      total: (_data.Freeze / sum_appliances) * 100,
    });
    ChartAppliances[4].data.push({
      y: _data.Wash,
      total: (_data.Wash / sum_appliances) * 100,
    });

    let sum_dependency = _data.NoToilet + _data.IsRuinedHouse + _data.WaterPipe + _data.WaterPond;

    ChartDependency[0].data.push({
      y: _data.NoToilet,
      total: (_data.NoToilet / sum_dependency) * 100,
    });
    ChartDependency[1].data.push({
      y: _data.IsRuinedHouse,
      total: (_data.IsRuinedHouse / sum_dependency) * 100,
    });
    ChartDependency[2].data.push({
      y: _data.WaterPipe,
      total: (_data.WaterPipe / sum_dependency) * 100,
    });
    ChartDependency[3].data.push({
      y: _data.WaterPond,
      total: (_data.WaterPond / sum_dependency) * 100,
    });

    let sum_house = _data.NoToilet + _data.IsRuinedHouse;
    ChartHouse[0].data.push({
      y: _data.NoToilet,
      total: (_data.NoToilet / sum_house) * 100,
    });
    ChartHouse[1].data.push({
      y: _data.IsRuinedHouse,
      total: (_data.IsRuinedHouse / sum_house) * 100,
    });

    let sum_water = _data.NoToilet + _data.IsRuinedHouse + _data.WaterPipe + _data.WaterPond;

    ChartWater[0].data.push({
      y: _data.WaterPipe,
      total: (_data.WaterPipe / sum_water) * 100,
    });
    ChartWater[1].data.push({
      y: _data.WaterPond,
      total: (_data.WaterPond / sum_water) * 100,
    });
    ChartWater[2].data.push({
      y: _data.WaterGround,
      total: (_data.WaterGround / sum_water) * 100,
    });
    ChartWater[3].data.push({
      y: _data.WaterOther,
      total: (_data.WaterOther / sum_water) * 100,
    });

    let sum_land = _data.NoToilet + _data.IsRuinedHouse + _data.WaterPipe + _data.WaterPond;

    ChartLand[0].data.push({
      y: _data.NoOwnLand,
      total: (_data.NoOwnLand / sum_land) * 100,
    });
    ChartLand[1].data.push({
      y: _data.SmallLand,
      total: (_data.SmallLand / sum_land) * 100,
    });
    ChartLand[2].data.push({
      y: _data.BigLand,
      total: (_data.BigLand / sum_land) * 100,
    });
  });

  let result = {
    chartIncome: {
      chart: {
        type: "column",
        style: {
          fontFamily: "Kanit",
        },
      },
      title: {
        align: "center",
        text: "ปีการศึกษา 2/2561 – 1/2565",
      },
      subtitle: {
        align: "center",
        text: null,
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "รายได้ครัวเรือน (บาท)",
        },
      },
      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            formatter: function () {
              return this.y ? numberWithCommas(this.y.toFixed(2)) : 0;
            },
            allowOverlap: true,
            crop: false,
            enabled: true,
          },
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> บาท<br/>',
      },

      series: [
        {
          name: "รายได้เฉลี่ยครัวเรือน",
          colorByPoint: true,
          data: ChartInCome,
        },
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
        series: [
          {
            name: "2/2561",
            id: "2/2561",
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25612.north_money / SUFFIX_YEAR_INDEX.y25612.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25612.central_money / SUFFIX_YEAR_INDEX.y25612.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25612.south_money / SUFFIX_YEAR_INDEX.y25612.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25612.northeast_money / SUFFIX_YEAR_INDEX.y25612.northeast_form],
            ],
          },
          {
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25621.north_money / SUFFIX_YEAR_INDEX.y25621.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25621.central_money / SUFFIX_YEAR_INDEX.y25621.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25621.south_money / SUFFIX_YEAR_INDEX.y25621.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25621.northeast_money / SUFFIX_YEAR_INDEX.y25621.northeast_form],
            ],
            name: "1/2562",
            id: "1/2562",
          },
          {
            name: "2/2562",
            id: "2/2562",
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25622.north_money / SUFFIX_YEAR_INDEX.y25622.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25622.central_money / SUFFIX_YEAR_INDEX.y25622.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25622.south_money / SUFFIX_YEAR_INDEX.y25622.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25622.northeast_money / SUFFIX_YEAR_INDEX.y25622.northeast_form],
            ],
          },
          {
            name: "1/2563",
            id: "1/2563",
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25631.north_money / SUFFIX_YEAR_INDEX.y25631.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25631.central_money / SUFFIX_YEAR_INDEX.y25631.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25631.south_money / SUFFIX_YEAR_INDEX.y25631.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25631.northeast_money / SUFFIX_YEAR_INDEX.y25631.northeast_form],
            ],
          },
          {
            name: "2/2563",
            id: "2/2563",
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25632.north_money / SUFFIX_YEAR_INDEX.y25632.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25632.central_money / SUFFIX_YEAR_INDEX.y25632.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25632.south_money / SUFFIX_YEAR_INDEX.y25632.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25632.northeast_money / SUFFIX_YEAR_INDEX.y25632.northeast_form],
            ],
          },
          {
            name: "1/2564",
            id: "1/2564",
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25641.north_money / SUFFIX_YEAR_INDEX.y25641.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25641.central_money / SUFFIX_YEAR_INDEX.y25641.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25641.south_money / SUFFIX_YEAR_INDEX.y25641.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25641.northeast_money / SUFFIX_YEAR_INDEX.y25641.northeast_form],
            ],
          },
          {
            name: "2/2564",
            id: "2/2564",
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25642.north_money / SUFFIX_YEAR_INDEX.y25642.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25642.central_money / SUFFIX_YEAR_INDEX.y25642.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25642.south_money / SUFFIX_YEAR_INDEX.y25642.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25642.northeast_money / SUFFIX_YEAR_INDEX.y25642.northeast_form],
            ],
          },
          {
            name: "1/2565",
            id: "1/2565",
            data: [
              ["เหนือ", SUFFIX_YEAR_INDEX.y25651.north_money / SUFFIX_YEAR_INDEX.y25651.north_form],
              ["กลาง", SUFFIX_YEAR_INDEX.y25651.central_money / SUFFIX_YEAR_INDEX.y25651.central_form],
              ["ใต้", SUFFIX_YEAR_INDEX.y25651.south_money / SUFFIX_YEAR_INDEX.y25651.south_form],
              ["ตะวันออกเฉียงใต้", SUFFIX_YEAR_INDEX.y25651.northeast_money / SUFFIX_YEAR_INDEX.y25651.northeast_form],
            ],
          },
        ],
      },
    },
    ChartDifficultConditions,
    ChartLight,
    ChartAppliances,
    ChartDependency,
    ChartHouse,
    ChartWater,
    ChartLand,
    chartIncomeTable,
  };

  return result;
};

const PageCctPmt = (props) => {
  const [loading, setLoading] = useState(true);
  const [initData, setInitData] = useState(null);
  let [data, setData] = useState({
    chartIncome: {},
    chartIncomeTable: [],
    ChartDifficultConditions: {},
    ChartLight: {},
  });
  const [filter, setFilter] = useState({
    year: ["y25632", "y25641", "y25642", "y25651"],
    record_type: "jonextra",
  });

  useEffect(async () => {
    setLoading(true);
    let dataSummaryAllyear = await api.getInitData("dashboard-pmt");
    console.log("dataSummaryAllyear", dataSummaryAllyear);
    setInitData(dataSummaryAllyear.data);
  }, []);

  useEffect(() => {
    // no have data
    if (!initData) return null;

    setLoading(false);
    setData(mapData(initData, filter.year, filter.record_type));
  }, [filter, initData]);

  let titleChart = filter.year.map((x) => MAP_YEAR[x]);

  return (
    <Layout isLoading={loading} Role={["partner", "eef"]}>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          backgroundImage: "url(" + bg + ")",
          backgroundSize: "contain",
        }}
      >
        <div className="h-[220px] w-[1px]"></div>

        <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered mt-[180px] mb-[100px]">
          <div className="flex flex-col 2xl:flex-row 2xl:justify-between">
            <p className="font-bold text-3xl mb-2 text-[#0E3165] mt-2 w-full 2xl:w-1/2">ข้อมูลครัวเรือนนักเรียนยากจน/ยากจนพิเศษ</p>
            <div className="mt-2 mb-2">
              <div className="flex flex-col 2xl:flex-row gap-6">
                <div className="flex gap-2">
                  <div className="my-auto">เลือกปี:</div>
                  <div className="flex">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                        minWidth: "200px",
                      }}
                      placeholder="Please select"
                      value={filter.year}
                      onChange={(value) => {
                        setFilter({
                          ...filter,
                          year: value.sort((a, b) => {
                            return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
                          }),
                        });
                      }}
                      options={[
                        {
                          label: "2/2561",
                          value: "y25612",
                        },
                        {
                          label: "1/2562",
                          value: "y25621",
                        },
                        {
                          label: "2/2562",
                          value: "y25622",
                        },
                        {
                          label: "1/2563",
                          value: "y25631",
                        },
                        {
                          label: "2/2563",
                          value: "y25632",
                        },
                        {
                          label: "1/2564",
                          value: "y25641",
                        },
                        {
                          label: "2/2564",
                          value: "y25642",
                        },
                        {
                          label: "1/2565",
                          value: "y25651",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="my-auto">ประเภท:</div>
                  <Select
                    allowClear
                    style={{
                      width: 150,
                    }}
                    placeholder="Please select"
                    value={filter.record_type}
                    onChange={(value) => {
                      setFilter({
                        ...filter,
                        record_type: value,
                      });
                    }}
                    options={[
                      {
                        label: "นร.ทั้งหมด",
                        value: "all",
                      },
                      {
                        label: "นร.ยากจนพิเศษ",
                        value: "jonextra",
                      },
                      {
                        label: "นร.ยากจน",
                        value: "jon",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* </Affix> */}

          {/* <div className="font-bold text-2xl mb-2 text-[#0E3165] mt-[20px]">
            ข้อมูลรายได้ครัวเรือน
          </div>
          <p>
            ข้อมูลรายได้เฉลี่ยของสมาชิกในครัวเรือนและจำนวนเฉลี่ยของสมาชิกในครัวเรือนตั้งแต่ปีการศึกษา
            2/2560 – 1/2562
          </p>

          <HighchartsReact highcharts={Highcharts} options={data.chartIncome} />

          <div className="flex justify-center w-full mb-12">
            <div className="w-3/5">
              <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div class="overflow-hidden">
                      <table class="min-w-full text-left text-sm font-light">
                        <thead class="border-b font-medium dark:border-neutral-500">
                          <tr>
                            <th
                              scope="col"
                              class="px-4 py-2 text-black bg-green-300"
                            >
                              ปีการศึกษา
                            </th>
                            <th
                              scope="col"
                              class="px-4 py-2 text-black bg-green-300 text-center"
                            >
                              รายได้ครัวเรือนเฉลี่ย(บาท){" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.chartIncomeTable.map((x) => (
                            <tr class="border-b dark:border-neutral-500">
                              <td class="whitespace-nowrap px-4 py-2 font-medium">
                                {x.title}
                              </td>
                              <td class="whitespace-nowrap px-4 py-2 text-right">
                                {x.avg}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="font-bold text-2xl mb-2 text-[#0E3165] mt-2">สภาวะยากลำบาก</div>
          <p>
            ในปีการศึกษา 2/2560 – 1/2562 นักเรียนยากจนและยากจนพิเศษแต่ละคนต่างกมีสภาวะความยากลำบากที่แตกต่างกันไป เช่น การมีภาระพึ่งพิง สภาพบ้านชำรุด
            ไม่มียานพาหนะ และไม่มีที่ดินทำกิน โดยการมีภาระพึ่งพิงหมายถึง การมีคนพิการ/เจ็บป่วยเรื้อรัง ผู้สูงอายุเกินกว่า 60 ปี มีคนอายุ 15-65
            ปีที่ว่างงาน หรือเป็นพ่อ/แม่เลี้ยงเดี่ยว สภาพบ้านชำรุดทรุดโทรม หมายถึง มีสภาพไม่ปลอดภัย ไม่มีห้องส้วมในที่อยู่อาศัย หรือบริเวณบ้าน
            ยานพาหนะในครัวเรือน หมายถึง การครอบครองยานพาหนะของครัวเรือนนั้น ๆ และที่ดินทำกิน หมายถึง การมีที่ดินที่ใช้ในการทำกิน
            ไม่ว่าจะเป็นที่ดินของตนเองหรือเช่ามาจากผู้อื่นก็ตาม โดยจำแนกออกเป็น ไม่มีที่ดินทำกิน มีที่ดินทำกินไม่เกิน 1 ไร่ และมีที่ดินทำกินได้ 1 ถึง
            5 ไร่
          </p>
          <Chart
            options={{
              title: "",
              type: "column",
              categories: titleChart,
              yAxis: {
                title: {
                  text: "จำนวนครัวเรือน (ร้อยละ)",
                },
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    formatter: function () {
                      return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                    },
                    allowOverlap: true,
                    crop: false,
                    enabled: true,
                  },
                },
              },
            }}
            click={({ e, category, name }) => {}}
            containerProps={{ style: { height: "260px" } }}
            style={{ width: "100px", height: "320px" }}
            data={data.ChartDifficultConditions}
          />

          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col flex-1 px-2 md:px-8">
              <p className="font-bold text-2xl mb-2 text-[#0E3165] mt-12">มีภาระพึ่งพิงในครัวเรือน</p>
              <Chart
                options={{
                  title: "",
                  type: "bar",
                  categories: titleChart,
                  plotOptions: {
                    series: {
                      borderWidth: 0,
                      dataLabels: {
                        formatter: function () {
                          return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                        },
                        allowOverlap: true,
                        crop: false,
                        enabled: true,
                      },
                    },
                  },
                  yAxis: {
                    title: {
                      text: "จำนวนครัวเรือน (ร้อยละ)",
                    },
                  },
                }}
                click={({ e, category, name }) => {}}
                containerProps={{ style: { height: "630px" } }}
                style={{ width: "100px", height: "630px" }}
                data={data.ChartDependency}
              />
            </div>
            <div className="flex flex-col flex-1 px-2 md:px-8">
              <p className="font-bold text-2xl mb-2 text-[#0E3165] mt-12">สภาพบ้านชำรุดทรุดโทรม</p>
              <Chart
                options={{
                  title: "",
                  type: "bar",
                  categories: titleChart,
                  yAxis: {
                    title: {
                      text: "จำนวนครัวเรือน (ร้อยละ)",
                    },
                  },
                  plotOptions: {
                    series: {
                      borderWidth: 0,
                      dataLabels: {
                        formatter: function () {
                          return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                        },
                        allowOverlap: true,
                        crop: false,
                        enabled: true,
                      },
                    },
                  },
                }}
                click={({ e, category, name }) => {}}
                containerProps={{ style: { height: "630px" } }}
                style={{ width: "100px", height: "630px" }}
                data={data.ChartHouse}
              />
            </div>
          </div>

          {/* <div className="font-bold text-2xl mb-2 text-[#0E3165] mt-12">
            ยานพาหนะในครัวเรือน
          </div>
          <Chart
            options={{
              title: "",
              type: "column",
              categories: titleChart,
              yAxis: {
                title: {
                  text: "จำนวนครัวเรือน (ร้อยละ)",
                },
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    formatter: function () {
                      return this.point.total
                        ? numberWithCommas(this.point.total.toFixed(2))
                        : 0;
                    },
                    allowOverlap: true,
                    crop: false,
                    enabled: true,
                  },
                },
              },
            }}
            click={({ e, category, name }) => { }}
            containerProps={{ style: { height: "260px" } }}
            style={{ width: "100px", height: "320px" }}
            data={data.ChartDifficultConditions}
          /> */}

          <div className="font-bold text-2xl mb-2 text-[#0E3165] mt-12">ที่ดินทำกิน</div>
          <Chart
            options={{
              title: "",
              type: "column",
              categories: titleChart,
              yAxis: {
                title: {
                  text: "จำนวนครัวเรือน (ร้อยละ)",
                },
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    formatter: function () {
                      return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                    },
                    allowOverlap: true,
                    crop: false,
                    enabled: true,
                  },
                },
              },
            }}
            click={({ e, category, name }) => {}}
            containerProps={{ style: { height: "260px" } }}
            style={{ width: "100px", height: "320px" }}
            data={data.ChartLand}
          />

          <div className="font-bold text-2xl mb-2 text-[#0E3165]">แหล่งไฟฟ้าและเครื่องใช้ไฟฟ้า</div>
          <p>
            ในส่วนของแหล่งไฟฟ้าและเครื่องใช้ไฟฟ้าสำหรับครัวเรือนแล้วเป็นปัจจัยที่สำคัญมากเช่นกัน
            โดยแต่ละครัวเรือนจะมีแหล่งไฟฟ้าสำหรับการใช้งานที่แตกต่างกัน เช่น ใช้เครื่องปั่นไฟ ใช้ไฟต่อพ่วง ใช้ไฟมิเตอร์และอื่นๆ
            แต่ในขณะเดียวกันก็ยังมีบางครัวเรือนที่ไม่มีไฟฟ้าและไม่มีเครื่องใช้ไฟฟ้าภายในครัวเรือน เครื่องใช้ไฟฟ้าในที่นี้ จะหมายถึง เครื่องปรับอากาศ
            โทรทัศน์ คอมพิวเตอร์ ตู้เย็น และเครื่องซักผ้า ซึ่งเครื่องใช้ไฟฟ้าเหล่านี้จะต้องยังสามารถใช้งานได้ตามปกติ ไม่ชำรุดเสียหาย
          </p>

          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col flex-1 px-2 md:px-8">
              <p className="font-bold text-2xl mb-2 text-[#0E3165] mt-12">แหล่งไฟฟ้า</p>
              <Chart
                options={{
                  title: "",
                  type: "bar",
                  categories: titleChart,
                  plotOptions: {
                    series: {
                      borderWidth: 0,
                      dataLabels: {
                        formatter: function () {
                          return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                        },
                        allowOverlap: true,
                        crop: false,
                        enabled: true,
                      },
                    },
                  },
                  yAxis: {
                    title: {
                      text: "จำนวนครัวเรือน (ร้อยละ)",
                    },
                  },
                }}
                click={({ e, category, name }) => {}}
                containerProps={{ style: { height: "630px" } }}
                style={{ width: "100px", height: "630px" }}
                data={data.ChartLight}
              />
            </div>
            <div className="flex flex-col flex-1 px-2 md:px-8">
              <p className="font-bold text-2xl mb-2 text-[#0E3165] mt-12">เครื่องใช้ไฟฟ้า</p>
              <Chart
                options={{
                  title: "",
                  type: "bar",
                  categories: titleChart,
                  yAxis: {
                    title: {
                      text: "จำนวนครัวเรือน (ร้อยละ)",
                    },
                  },
                  plotOptions: {
                    series: {
                      borderWidth: 0,
                      dataLabels: {
                        formatter: function () {
                          return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                        },
                        allowOverlap: true,
                        crop: false,
                        enabled: true,
                      },
                    },
                  },
                }}
                click={({ e, category, name }) => {}}
                containerProps={{ style: { height: "630px" } }}
                style={{ width: "100px", height: "630px" }}
                data={data.ChartAppliances}
              />
            </div>
          </div>

          <div className="font-bold text-2xl mb-2 text-[#0E3165] mt-12">แหล่งน้ำที่ใช้ในครัวเรือน</div>
          <p>
            {" "}
            ในแต่ละครัวเรือนจำเป็นต้องมีแหล่งน้ำที่ใช้ภายในครัวเรือน โดยแหล่งน้ำเหล่านั้น มีที่มาแตกต่างกันไป ไม่ว่าจะเป็นน้ำประปา
            น้ำบ่อ/น้ำฝน/น้ำประปาภูเขา/แม่น้ำลำธาร น้ำบาดาล และจากแหล่งน้ำอื่น ๆ
          </p>

          <Chart
            options={{
              title: "",
              type: "column",
              //     categories: titleChart,
              categories: titleChart,
              yAxis: {
                title: {
                  text: "จำนวนครัวเรือน (ร้อยละ)",
                },
              },

              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    formatter: function () {
                      return this.point.total ? numberWithCommas(this.point.total.toFixed(2)) : 0;
                    },
                    allowOverlap: true,
                    crop: false,
                    enabled: true,
                  },
                },
              },
            }}
            click={({ e, category, name }) => {}}
            containerProps={{ style: { height: "260px" } }}
            style={{ width: "100px", height: "320px" }}
            data={data.ChartWater}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PageCctPmt;
